import TextareaAutosize from "react-textarea-autosize";
import { fromJS } from "immutable";
import React from "react";

const TextArea = props => {
  const nameAsArray = props.name.split(".");

  return (
    <div className="form-group">
      <label>{props.title}</label>
      <TextareaAutosize
        name={props.name}
        id={props.name}
        cols="30"
        rows={props.rows}
        value={props.state.getIn(nameAsArray)}
        onChange={value =>
          props.onChange(props.state.setIn(nameAsArray, value.target.value))
        }
        placeholder={props.placeholder}
      />
    </div>
  );
};

TextArea.defaultProps = {
  name: "",
  state: fromJS({}),
  rows: 4,
  title: "!!!Title!!!",
  placeholder: "",
  onChange: value => {
    console.log(value);
  }
};

export default TextArea;
