import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { fromJS } from "immutable";
import Image from "../components/Image";

const getColor = props => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

function StyledDropzone(props) {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    props.uploadFile(acceptedFiles, props.name);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop, accept: "image/*" });

  let src = "";
  if (props.state.getIn(props.name.split("."))) {
    src = props.state.getIn(props.name.split("."));
  }

  return (
    <div className="dropzone">
      <label>{props.title}</label>
      {src.length ? <Image src={src} width={200} height={200} /> : null}
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <p>Bild hier ablegen oder klicken.</p>
      </Container>
    </div>
  );
}

StyledDropzone.defaultProps = {
  name: "",
  title: "",
  state: fromJS({}),
  uploadFile: value => {
    console.log(value);
  }
};

export default StyledDropzone;
