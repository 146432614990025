import React from "react";

export default ({ close }) => (
  <div className="menu hamburgermenu">
    <span className="iconwrapper">
      <a
        href="https://goo.gl/maps/1hPb8XYqY4fdQQ2w8"
        target="_blank"
        rel="noopener noreferrer"
        title="In Google Maps öffnen"
      >
        <b>BlumenKunst</b>
        <br />
        Katharina Sternad
        <br />
        Aframerstraße 16
        <br />
        8410 Wildon
      </a>
    </span>

    <br />
    <br />

    <span className="iconwrapper">
      <a href="tel:+436606433136">+43 660 64 33 136</a>
    </span>

    <br />
    <br />

    <span className="iconwrapper">
      <a href="mailto:kathi@blumenkunst.at">kathi@blumenkunst.at</a>
    </span>
  </div>
);
