import React from "react";

export default ({ open, ...props }) => (
  <div className="cms">
    <div className="login">
      <div className="row">
        <div className="col-xs-12">
          <form
            onSubmit={event => {
              props.login(event);
            }}
          >
            <input
              type="text"
              name="username"
              placeholder="username"
              value={props.username}
              onChange={event =>
                props.setState({ username: event.target.value })
              }
            />
            <input
              type="password"
              name="password"
              placeholder="password"
              value={props.password}
              onChange={event =>
                props.setState({ password: event.target.value })
              }
            />
            <input type="submit" value="login" />
          </form>
        </div>
      </div>
    </div>
  </div>
);
