import React, { Component } from "react";
import { Controller, Scene } from "react-scrollmagic";
import Hamburger from "../components/Hamburger";
import Image from "../components/Image";
// import AnchorLink from "react-anchor-link-smooth-scroll";
// import Instagram from "../components/Instagram";
import GLightbox from 'glightbox';
// import Content from "../components/Content";

class Home extends Component {
  constructor(props){
    super(props);
    this.lightboxDescription = GLightbox({
      selector: 'glightbox'
    });
  }

  render() {
    if (this.props.data.loading === true) {
      return <span>loading...</span>;
    }

    setTimeout(() => {
    this.lightboxDescription.reload();
    }, 500);

    return (
      <Controller>
        <Scene duration={10} pin={true} triggerHook="onLeave">
          <header>
            <div className="headerWrapper desktop">
              <div className="">
                <div className="row middle-sm">
                  <div className="col-sm-6 text-left">
                    <img
                      src="/logo_schwarz.png"
                      width={118}
                      className={"logo"}
                      alt="Logo von Blumenkunst Katharina Sternad"
                    />
                  </div>
                  <div className="col-sm-5 text-right">
                    {/*<ul>*/}
                    {/*  <li>*/}
                    {/*    <a href="#home">Home</a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="#">Über Mich</a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="#">Arbeiten</a>*/}
                    {/*  </li>*/}
                    {/*  <li>*/}
                    {/*    <a href="#">Kontakt</a>*/}
                    {/*  </li>*/}
                    {/*</ul>*/}
                  </div>
                  <div className="col-sm-1 text-right">
                    <p className="socialMedia">
                      <a
                          href="https://www.facebook.com/blumenkunst.at/"
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                        <img src="/social/ic_fb.svg" alt="" />
                      </a>
                      <a href="mailto:kathi@blumenkunst.at">
                        <img src="/social/ic_mail.svg" alt="" />
                      </a>
                    </p>
                    <p className="socialMedia">
                      <a
                          href="https://www.instagram.com/blumenkunstkathi/"
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                        <img src="/social/ic_ig.svg" alt="" />
                      </a>
                      <a href="tel:+436606433136">
                        <img src="/social/ic_phone.svg" alt="" />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile">
              <a href="tel:+436606433136" className={"callLink"}>
                <img src="/social/ic_phone.svg" alt="" />
                +43 660 64 33 136
              </a>
              <Hamburger />
            </div>
          </header>
        </Scene>

        <Scene duration={600} classToggle="in-view">
          <section className={"hero"}>
            <Image src={"hero1.jpg"} width={1920} />
          </section>
        </Scene>

        <div className="spacer"/>

        <Scene duration={600} classToggle="in-view">
          <section className={"lightGrey"}>
            <div className="wrapper">
              <div className="row">
                <div className="col-xs-12 text-center">
                  <h3>Meine Leistungen</h3>
                  <h1>Alles auf einen Blick</h1>
                  <div className="spacer"/>
                </div>
                <div className="col-xs-12 col-sm-4">
                  <h4>Blumenabo</h4>
                  <p>
                    Wöchentlich, monatlich oder 4 mal im Jahr immer frische, saisonale Sträuße arrangiert und direkt zu Ihnen zugestellt!
                    <br/>(Freie Zustellung im Raum Wildon.)
                  </p>
                </div>
                <div className="col-xs-12 col-sm-4">
                  <h4>Schnittblumen</h4>
                  <p>
                    Ich respektiere den natürlichen Kreislauf der Natur und binde ihn in meine Arbeiten ein.
                    <br/><br/>
                    Saisonale Blumen, Pflanzen und Naturmaterialien werden zu liebevoll gestalteten Sträußen und Arrangements verarbeitet.
                  </p>
                </div>
                <div className="col-xs-12 col-sm-4">
                  <h4>Hochzeitsfloristik</h4>
                  <p>
                    Vom Brautstrauß, Anstecker, Autoschmuck und Kirchenschmuck bis zum Tischschmuck gestalte ich individuelle Hochzeitsdekoration.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Scene>

        <div className="spacer" />

        {/*<Scene duration={600} classToggle="in-view">*/}
        {/*  <section className={"lightGrey"}>*/}
        {/*    <div className="instagram">*/}
        {/*      <div className="wrapper">*/}
        {/*        <div className="row">*/}
        {/*          <div className="col-xs-6 col-sm-4 hidden-mobile">*/}
        {/*            <h4>#hochzeitsfloristik</h4>*/}
        {/*          </div>*/}
        {/*          <div className="col-xs-12 col-sm-12 text-center last-xs">*/}
        {/*            <h4>@blumenkunstkathi</h4>*/}
        {/*          </div>*/}
        {/*          <div className="col-xs-6 col-sm-4 text-right last-sm hidden-mobile">*/}
        {/*            <h4>#weddingstyle</h4>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div className="spacer hidden-mobile" />*/}
        {/*        <div className="row">*/}
        {/*          <Instagram />*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </section>*/}
        {/*</Scene>*/}

        <Scene duration={600} classToggle="in-view">
          <section className={"hero contact"} id="kontakt">
            <a
              href="https://goo.gl/maps/1hPb8XYqY4fdQQ2w8"
              target="_blank"
              rel="noopener noreferrer"
              title="In Google Maps öffnen"
            >
              <Image src={"map.png"} width={1920} />
            </a>
          </section>
        </Scene>

        {/*<Scene duration={600} classToggle="in-view">*/}
        {/*  <section className={"greeting contact"}>*/}
        {/*    <div className="wrapper">*/}
        {/*      <div className="row">*/}
        {/*        <div className="col-xs-12 col-sm-5 col-sm-offset-1">*/}
        {/*          <Image*/}
        {/*            src={this.props.data.contact.imageDesktop}*/}
        {/*            className={"hidden-mobile"}*/}
        {/*          />*/}
        {/*          <Image*/}
        {/*            src={this.props.data.contact.imageMobile}*/}
        {/*            className={"hidden-desktop"}*/}
        {/*            width={320}*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*        <div className="col-xs-12 col-sm-4 col-sm-offset-1 text mobilePadding">*/}
        {/*          <Content*/}
        {/*            text={this.props.data.contact.title}*/}
        {/*            wrapper={"h2"}*/}
        {/*          />*/}
        {/*          <Content*/}
        {/*            text={this.props.data.contact.subtitle}*/}
        {/*            wrapper={"h3"}*/}
        {/*          />*/}
        {/*          <Content text={this.props.data.contact.text} showP />*/}
        {/*          <p>*/}
        {/*            <a href="datenschutz.html">Impressum & Datenschutz</a>*/}
        {/*          </p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </section>*/}
        {/*</Scene>*/}

        <Scene duration={600} classToggle="in-view">
          <footer className={"footer"}>
            <div className="wrapper">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <img
                      src="/logo_weiss.png"
                      width={118}
                      className={"logo"}
                      alt="Logo von Blumenkunst Katharina Sternad"
                  />

                  <ul>
                    <li>
                      <a href="#ueber">Über Mich</a>
                    </li>
                    <li>
                      <a href="#arbeiten">Arbeiten</a>
                    </li>
                    <li>
                      <a href="#kontakt">Kontakt</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </Scene>
      </Controller>
    );
  }
}

export default Home;
