import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "moment/locale/de-at";

import "./App.scss";
import Home from "./pages/Home";
import CMS from "./CMS/CMS";
import axios from "axios";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const url =
      process.env.NODE_ENV === "development"
        ? "http://blumenkunst.at.test/public/cms/data"
        : "/cms/data";

    axios
      .get(url)
      .then(response => {
        // handle success
        this.setState({ ...response.data, loading: false });
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  };

  render() {
    return (
      <Router>
        <Route
          path="/"
          exact
          component={() => <Home data={{ ...this.state }} />}
        />
        <Route
          path="/admin"
          exact
          component={() => <CMS data={{ ...this.state }} />}
        />
      </Router>
    );
  }
}

export default App;
